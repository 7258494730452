import React, { useState, useEffect, useRef } from 'react'
import VolumeIcon from '../../assets/images/volume.svg'
import LogoRec from '../../assets/images/logo-rec.png'
import BoucleAudioIntro from '../../assets/sound/INTRO_AMBIANCE_BOUCLE.mp3'
import IntroLogoSound from '../../assets/sound/INTRO_LOGOS.mp3'
import './index.scss'
import { Howl } from 'howler'
import LogoV2V from '../../assets/images/logo-v2v-blancpng.png'
import ReactHowler from 'react-howler/lib/ReactHowler';

const TransitionScreen = ({ soundScreen, logoScreen, setCurrentStep }) => {
    const [state, setState] = useState(0)

    useEffect(() => {
        setTimeout(() => {
            soundScreen.current.classList.add('on')
        }, 300)

        setTimeout(() => {
            soundScreen.current.classList.remove('on')
        }, 5300)

        setTimeout(() => {
            setState(1)
        }, 7300)
        
        setTimeout(() => {
            logoScreen.current.classList.add('on')
        }, 8300)

        setTimeout(() => {
            logoScreen.current.classList.remove('on')
        }, 11300)

        setTimeout(() => {
            setCurrentStep(0)
        }, 14300)
    }, [])

    return (
        <section className="transitionScreen">
            <ReactHowler
                src={BoucleAudioIntro}
                playing={true}
                loop={true}
                html5={true}
                volume={1}
            />
            {
                state === 0 && (
                    <div ref={soundScreen} className={`logoSound`}>
                        <img className="volumeIcon" src={VolumeIcon} alt="" />
                        <p>Pour une meilleure expérience, <span>utilise tes écouteurs.</span></p>
                    </div>
                )
            }

            {
                state === 1 && (
                    <>
                        <ReactHowler
                            src={IntroLogoSound}
                            playing={true}
                            html5={true}
                            volume={1}
                        />
                        <div className="logoScreen" ref={logoScreen}>
                            <img src={LogoRec} alt="" />
                            <img src={LogoV2V} alt="" />
                        </div>
                    </>
                )
            }
        </section>
    )
}

export default TransitionScreen