import React, { useEffect } from 'react'           
import Footer from '../../components/Footer' 
import './index.scss'

const StartingScreen = ({ setCurrentStep, boucleProd }) => {
    useEffect(() => {
        window.digitalData={							
            settings:{						
                reportSuites:"wmg,wmgfr"					
            },						
            page:{						
                pageInfo:{					
                    pageName:"DA Uzi:Le chemin des braves Game and Contest:Landing:Intro",				
                    server:"DA Uzi:Site",				
                    platform:"MIS Custom Page",				
                    devTeam:"Warner Music France"				 
                },					
                category:{					
                    primaryCategory:"DA Uzi:Landing Page",				
                    pageType:"Landing Page:Contest"				
                }					
            },						
            content:{						
                artist:"DA Uzi",					
                label:"Warner Music International",					
                sublabel:"Warner Music France"					
            }						
        }									
        document.dispatchEvent(new CustomEvent('SPAPageTriggered'));
    }, [])

    return (
        <>
        <section className="startingScreenContainer">

            <div className="titles">
                <h1>DA UZI</h1>
                <h2>A une mission</h2>
                <h2>pour toi</h2>
            </div>
            
            <p className="helpDA">Aide-le et tente de gagner des places pour la soirée de lancement de son nouvel album "Le chemin des braves".</p>
            <p className="yourTurn">À toi de jouer !</p>
            
            <button onClick={() => {
                setCurrentStep(-0.6)
                boucleProd.current.IndicationAudio.play()
            }}>Commencer</button>
            
            <Footer />
        </section>
        </>
    )
}

export default StartingScreen