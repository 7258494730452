import React, { useEffect } from 'react'
import FacetimeVideo from '../../assets/videos/facetimevideo.mp4'
import MuteIcon from '../../assets/icons/mute.svg'
import SpeakerIcon from '../../assets/icons/speaker.svg'
import ClavierIcon from '../../assets/icons/clavier.svg'
import EndIcon from '../../assets/icons/endCall.svg'
import './index.scss'

const FacetimeCall = ({ videoFacetimeRef, isOpenFacetime, setCurrentStep }) => {
    return (
        <section className={`facetimeCall ${isOpenFacetime === true && 'on'}`}>
            <div className="bottom">
                <div>
                    <img src={MuteIcon} alt="" />
                    <img src={SpeakerIcon} alt="" />
                    <img src={EndIcon} alt="" />
                </div>
            </div>
            <video ref={videoFacetimeRef} onEnded={() => setCurrentStep(8)} playsInline autoPlay>
                <source src={FacetimeVideo}
                        type="video/mp4" />
                Sorry, your browser doesn't support embedded videos.
            </video>

        </section>
    )
}

export default FacetimeCall