const indications = [
    {
        id: "indicateur1",
        name: "Témoin 1",
        zone: [2.365010, 48.936050],
        text: "Nan en fait il est à la plaine au Stade de France, dépêche-toi !",
        isIndic: false,
        active: true,
        discover: "north",
        discovered: false,
        nextIndex: 1
    },
    {
        id: "indicateur2",
        name: "Témoin 2",
        zone: [2.362550, 48.918880],
        text: "Il a taillé il est vers La Courneuve, BK l’a vu au feu !",
        isIndic: false,
        active: true,
        discover: "south",
        discovered: false,
        nextIndex: 2
    },
    {
        id: "indicateur3",
        name: "Témoin 3",
        zone: [2.390820, 48.928400],
        text: "Ce gadaye il fuit à Auber, trace !",
        isIndic: false,
        active: true,
        discover: "east",
        discovered: false,
        nextIndex: 3
    },
    // {
    //     id: "indicateur9",
    //     name: "Témoin 4",
    //     zone: [2.470970, 48.923690],
    //     text: "Ha ouais nan il est à St Ouen en fait !",
    //     isIndic: false,
    //     active: true,
    //     nextIndex: 4
    // },
    // {
    //     id: "indicateur10",
    //     name: "Témoin 5",
    //     zone: [2.334267, 48.911729],
    //     text: "Je viens de voir le djo, il est à Aubervilliers, fais vite !",
    //     isIndic: false,
    //     active: true,
    //     nextIndex: 5
    // },
    {
        id: "indicateur11",
        name: "Témoin 6",
        zone: [2.386860, 48.913420],
        text: "Le gars que tu cherche est à l'ouest.",
        isIndic: true,
        active: true,
        nextIndex: 4
    }
]

export default indications