import React, { useEffect, useState } from 'react'

const TopInfosCall = ({ boucleProd, hasResponded, currentStep, setCurrentStep }) => {
    const [time, setTime] = useState(1)

    useEffect(() => {
        if (hasResponded) {
                const timer = setTimeout(() => {
                setTime(time + 1);
            }, 1000);

            return () => clearTimeout(timer);
        }
    });

    return (
        <div className="call_top_infos">
            <h1>LIBANAIS</h1>
            {hasResponded === false && <p>France</p>}
            {hasResponded && <p>{('0' + Math.floor((time / 60) % 60)).slice(-2)}:{('0' + Math.floor((time) % 60)).slice(-2)}</p>}
        </div>
    )
}

export default TopInfosCall