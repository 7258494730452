import React, { useEffect, useState, useRef } from 'react'
import LooseBg from '../../assets/images/looseBg.svg'
import './index.scss'
import CrameAudio from '../../assets/sound/GENERAL_CRAME.mp3'
import { Howl } from 'howler'

const Loose = ({ boucleProd, setCurrentStep, lastScreen, userName }) => {
    const audios = useRef(new Howl({
            src: [CrameAudio],
            html5: true,
            volume: 1,
            usingWebAudio: false,
            html5: true,
            mute: false,
            webAudio: false,
        })
    )
    
    useEffect(() => {
        audios.current.play()
    }, [])

    return (
        <section className='loose'>
            <p>Perdu</p>
            <div className='panelLoose'>
                <img src={LooseBg} alt="" />
                <div className='panelWrite'>
                    <div className='row'>
                        <p>{(Math.random() + 1).toString(36).substring(7)}</p>
                        <p>{new Date().toLocaleDateString('fr')}</p>
                    </div>
                    <p className="name">{userName}</p>
                </div>
            </div>
            <button onClick={() => {
                boucleProd.current.IndicationAudio.play()
                setCurrentStep(lastScreen)
            }}>Recommencer</button>
        </section>
    )
}

export default Loose