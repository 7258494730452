import React from "react"
import SilhouetteEnigme from '../../assets/images/silhouetteEnigme.svg'
import './index.scss'
import ElectricalBackground from '../../assets/images/triangle.svg'

const DoorIndication = ({ isLocked, enigmeText, type, text, img, enigmeState }) => {
    return (
        <section className={`doorIndication ${enigmeState === "goodFind" && "goodFind"} ${enigmeState === "badFind" && "badFind"}`}>
            <div className="textIndication">
                {enigmeState === "enigme" && <p>{text[0]}</p>}
                {enigmeState === "scanning" && <p>{text[1]}</p>}
                {enigmeState === "badFind" && <p>{text[2]}</p>}
                {enigmeState === "goodFind" && <p>{text[3]}</p>}
            </div>
            {
                type === "fingerprint" && (
                    <div className="silhouetteContainer fingerprintIconContainer"><img src={img} alt="" /></div>
                )
            }
           {
               type === "electrical" && (
                   <div className="electricalContainer">
                    <img className="electricalIcon" src={img} alt="" />
                    <img className="electricalBg" src={ElectricalBackground} alt="" />
                   </div>
               )
           }
           {
               type === "pression" && (
                   <div className="silhouetteContainer pressionIconContainer"><img src={img} alt="" /></div>
               )
           }
        </section>
    )
}

export default DoorIndication