import React, { useEffect, useState, useRef } from 'react'
import { distance } from '@turf/turf';
import FlecheBoussole from '../../assets/images/FLECHE_boussole.svg'
import './index.scss'

function bearing(lat1, lon1, lat2, lon2) {
    var longDiff = lon2 - lon1;
    var y        = Math.sin(longDiff) * Math.cos(lat2);
    var x        = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(longDiff);
  
    return ( toDegrees( Math.atan2( y, x ) ) + 360 ) % 360;
  }
  
  function toDegrees (angle) {
    return angle * (180 / Math.PI);
  }

const Boussole = ({ map, mapLng, mapLat, toFindLat, toFindLng, currentPlanqueIdx }) => {
    const boussoleRef = useRef(null)

    const [boussoleCoord, setBoussoleCoord] = useState(null)
    const [distancePoint, setDistancePoint] = useState(0)

    const [boussoleCoordonees, setBoussoleCoordonnees] = useState({})

    useEffect(() => {
        if (boussoleRef.current) {
            setBoussoleCoord(boussoleRef.current.getBoundingClientRect())
        }
    }, [boussoleRef.current])

    useEffect(() => {
        if (boussoleCoord && boussoleRef.current) {
            setBoussoleCoordonnees(
                map.current?.unproject({
                    x: boussoleCoord.left + 40  - document.querySelector('canvas').getBoundingClientRect().left,
                    y: boussoleCoord.bottom - 40 - document.querySelector('canvas').getBoundingClientRect().top 
                })
            )

            boussoleRef.current.style.transform = `rotate(${ bearing(boussoleCoordonees.lat, boussoleCoordonees.lng, toFindLat, toFindLng) }deg)`

            var to = [toFindLng, toFindLat]
            var from = [boussoleCoordonees.lng, boussoleCoordonees.lat]
            var options = {
                units: 'kilometres'
              }; 
    
            setDistancePoint(distance(to, from, options))
        }

        
    }, [mapLat, mapLng, currentPlanqueIdx, boussoleRef.current, boussoleCoord])

    return (
        <>
        {distancePoint && <div className='distance'>{distancePoint.toFixed(2)}km</div>}
        <div  ref={boussoleRef}   className='boussole'>
            <img src={FlecheBoussole} alt="" />
            <div className='test' style={{
                position: "absolute",
                left: "50%",
                top: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                transform: "translate3D(-50%, -50%, 0)"
            }}>
                
            <div className='interiorBoussole'>
            </div>
            </div>
        </div>
        </>
    )
}

export default Boussole