import React, { useState, useEffect, useRef } from 'react'
import Fil1Gauche from '../../assets/images/fil_1_gauche.png'
import Fil2Gauche from '../../assets/images/fil_2_gauche.png'
import Fil3Gauche from '../../assets/images/fil_3_gauche.png'
import Fil4Gauche from '../../assets/images/fil_4_gauche.png'
import Fil1Droite from '../../assets/images/fil_1_droite.png'
import Fil2Droite from '../../assets/images/fil_2_droite.png'
import Fil3Droite from '../../assets/images/fil_3_droite.png'
import Fil4Droite from '../../assets/images/fil_4_droite.png'


import FakeFil1Gauche from '../../assets/images/fake_fil_1_gauche.png'
import FakeFil2Gauche from '../../assets/images/fake_fil_2_gauche.png'
import FakeFil3Gauche from '../../assets/images/fake_fil_3_gauche.png'
import FakeFil4Gauche from '../../assets/images/fake_fil_4_gauche.png'
import FakeFil1Droite from '../../assets/images/fake_fil_1_droite.png'
import FakeFil2Droite from '../../assets/images/fake_fil_2_droite.png'
import FakeFil3Droite from '../../assets/images/fake_fil_3_droite.png'
import FakeFil4Droite from '../../assets/images/fake_fil_4_droite.png'
import ElectricalIcon from '../../assets/images/electricalIcon.svg'

import DoorIndication from '../DoorIndication'
import GoodFindIcon from '../../assets/images/goodFindIcon.svg'
import BadFindIcon from '../../assets/images/badFindIcon.svg'

import FilAudioFile from '../../assets/sound/JEU_CABLES_CLIQUE.mp3'

import useSound from 'use-sound';

import './index.scss'
import Help from '../Help'

const Fils = ({ map, playPlanqueOuverte, launchNotifAfterEnigme, playJeuReussi, playJeuRate, launchNotification, planques, setPlanques, setCurrentStep, setIsOnEnigme, currentPlanque }) => {
    const [filsGauche1] = useState([Fil1Gauche, FakeFil1Gauche, FakeFil3Gauche, FakeFil2Gauche])
    const [filsDroite1] = useState([Fil1Droite, FakeFil1Droite, FakeFil3Droite, FakeFil4Droite])
    const [filsGauche2] = useState([FakeFil4Gauche, Fil2Gauche, FakeFil3Gauche, Fil1Gauche])
    const [filsDroite2] = useState([FakeFil1Droite, Fil2Droite, FakeFil3Droite, FakeFil2Droite])
    const [filsGauche3] = useState([FakeFil2Gauche, FakeFil4Gauche, Fil3Gauche, FakeFil1Gauche])
    const [filsDroite3] = useState([FakeFil1Droite, FakeFil2Droite, Fil3Droite, FakeFil4Droite])
    const [filsGauche4] = useState([FakeFil3Gauche, FakeFil1Gauche, FakeFil4Gauche, Fil4Gauche])
    const [filsDroite4] = useState([FakeFil1Droite, FakeFil2Droite, FakeFil4Droite, Fil4Droite])
    const [enigmeState, setEnigmeState] = useState("enigme")
    

    const [phrases] = useState([
        "Pirate le système", "", "Echec du piratage", "Piratage réussi"
    ])

    const [state, setState] = useState([{
        gauche: 0,
        droite: 0
    }, {
        gauche: 0,
        droite: 3
    }, {
        gauche: 2,
        droite: 3
    }, {
        gauche: 2,
        droite: 1
    }])

    const [introPhrases] = useState(['Pirate le système', 'Echec du piratage', 'Piratage réussi'])

    const [filAudio] = useSound(FilAudioFile);

    const handleFil = (direction, index) => {
        filAudio()
        const _newState = state
        _newState[index][direction] = (state[index][direction] + 1) % 4
        setState([..._newState])
    }

    const handleUpdate = (index) => {
        const _planque = planques[index]
        _planque.active = false
        map.current.setPaintProperty(_planque.id, 'fill-color', "rgba(255, 255, 255, 0.3)");
        const newPlanques = [...planques];
        newPlanques[index] = _planque;
        setPlanques(newPlanques);
    }      

    const validateEnigme = () => {
        if (state[0].gauche === 0 && state[0].droite === 0
            && state[1].gauche === 1 && state[1].droite === 1
            && state[2].gauche === 2 && state[2].droite === 2
            && state[3].gauche === 3 && state[3].droite === 3) {
            setEnigmeState('goodFind')
            playJeuReussi()

            

            setTimeout(() => {
                playPlanqueOuverte()
            }, 200)

            setTimeout(() => {
                launchNotifAfterEnigme()
            }, 400)

            setTimeout(() => {
              setIsOnEnigme(false)
              handleUpdate(planques.findIndex((planque) => planque.id === currentPlanque))
            }, 3000)
          } else {
            setEnigmeState('badFind')
            playJeuRate()

            setTimeout(() => {
                setEnigmeState('enigme')
            }, 3000)
          }      
    }

    useEffect(() => {
        launchNotification('LIBANAIS', 'Trouve la bonne combinaison pour pirater la porte d’entrée.')
        
        const dumbNotication = setTimeout(() => {
            launchNotification('LIBANAIS', 'C’est comment t’as trouvé ?', 3000)
        }, 12000)

        return (() => {
            clearTimeout(dumbNotication);
        })
    },  [])
    
    return (
        <section className="filsContainer">
            
            <Help text={"Clique sur les bouts de câbles à droite et à gauche pour les relier parfaitement"} />
            <DoorIndication enigmeState={enigmeState} type="electrical" img={ElectricalIcon} isLocked={true} text={phrases} enigmeText={"Reconstitue l’iris pour dévérouiller la porte"} />
            
            <section className='filHandler'>
            <p>Danger</p>
            
            <div className="fils">
            <div className="fil fil1">
                <img src={filsGauche1[state[0].gauche]} alt="" />
                <img src={filsDroite1[state[0].droite]} alt="" />
            </div>
            <div className="fil fil2">
                <img onClick={() => handleFil("gauche", 1)} src={filsGauche2[state[1].gauche]} alt="" />
                <img  onClick={() => handleFil("droite", 1)} src={filsDroite2[state[1].droite]} alt="" />
            </div>
            <div className="fil fil3">
                <img onClick={() => handleFil("gauche", 2)} src={filsGauche3[state[2].gauche]} alt="" />
                <img  onClick={() => handleFil("droite", 2)} src={filsDroite3[state[2].droite]} alt="" />
            </div>
            <div className="fil fil4">
                <img onClick={() => handleFil("gauche", 3)} src={filsGauche4[state[3].gauche]} alt="" />
                <img  onClick={() => handleFil("droite", 3)} src={filsDroite4[state[3].droite]} alt="" />
            </div></div>
            </section>

                  
            {
            enigmeState === "enigme" && (
                <button className="validate" onClick={validateEnigme}>Valider</button>
            )
            }
            {
            enigmeState === "goodFind" && (
                <img className="smallIndicationIcon" src={GoodFindIcon} alt="" />
            )
            }
            {
            enigmeState === "badFind" && (
                <img className="smallIndicationIcon" src={BadFindIcon} alt="" />
            )
            }
        </section>
    )
}

export default Fils