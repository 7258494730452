import React, { useEffect, useState } from 'react'
import Footer from '../../components/Footer'
import DAProfil from '../../assets/images/DA_UZI_profil.png'
import VignetteLinkfire from '../../assets/images/vignetteLinkfire.jpg'
import "./index.scss"

const Win = () => {
    const [formFull, setFormFull] = useState(false)
    useEffect(() => {
        var connect_button = new window.WMGConnect(".wmg-button", {
            // Would you like a popup "Thank you" message to automatically appear once the user has been acquired?
            opt_in_thank_you_enabled: false,
            opt_in_form_target: '#formulaire',
         
            // Data sources are automatically generated based on how the campaign is set up but you can specify a custom one below
            // exacttarget_datasource: 'WMG_EdSheeran_PreSave'
        });

        connect_button.setCallback(function(connect_data){

            // Callback code
        
            console.log(connect_data);
            setFormFull(true)
        
        });
    })

    useEffect(() => {
        window.digitalData={							
            settings:{						
                reportSuites:"wmg,wmgfr"					
            },						
            page:{						
                pageInfo:{					
                    pageName:"DA Uzi:Le chemin des braves Game and Contest:Landing:Win",				
                    server:"DA Uzi:Site",				
                    platform:"MIS Custom Page",				
                    devTeam:"Warner Music France"				 
                },					
                category:{					
                    primaryCategory:"DA Uzi:Landing Page",				
                    pageType:"Landing Page:Contest"				
                }					
            },						
            content:{						
                artist:"DA Uzi",					
                label:"Warner Music International",					
                sublabel:"Warner Music France"					
            }						
        }
        
        document.dispatchEvent(new CustomEvent('SPAPageTriggered'));					
    }, [])
    
    return (
        <section className="winPage">
            <img src={DAProfil} alt="" />
            <h1><span>Merci</span> pour ton aide</h1>
            {
                formFull === false && (
                    <>
                        <p className="annonceSoiree">On fait une soirée pour la sortie de mon album « Le Chemin des braves ».<br />Laisse-moi tes coordonnées pour tenter d’y participer.</p>
                        <section id="formulaire"></section>
                        <p className='iosIndications'>Valider le formulaire nécessite la version IOs 13.4 minimum</p>
                    </>
                )
            }

            {
                formFull && (
                    <>
                    <p className="linkfireWait">Merci d'avoir participé. On te recontactera si tu as gagné.<br /> En attendant, écoute mon dernier morceau « On se reverra plus », avec Gazo.</p>
                    
                    <a className='linkfireLink' href="https://dauzi.lnk.to/onsereverraplusMe"><img src={VignetteLinkfire} alt="" /></a>
                    </>
                )
            }
            <Footer />
        </section>
    )
}

export default Win