import React from 'react'
import PolicePoste from '../../assets/icons/poste-de-police.png'
import IndicationPositionImg from '../../assets/images/positionIndicator.png'
import IndicateurSVG from '../../assets/images/FLECHE.svg'
import './index.scss'

const IndicationPosition = ({ positionCursorRef }) => {
    return (
        <div className='indicationPosition'>
            <img  ref={positionCursorRef} src={IndicateurSVG} className="indicatorArrow" alt="" />
            <img src={IndicationPositionImg} className="indicateur" alt="" />
        </div>
    )
}

export default IndicationPosition