import React, { useState, useRef, useEffect } from 'react'
import './index.scss'
import useInterval from '../../hooks/useInterval'
import DoorIndication from '../DoorIndication'
import CadenasSVG from '../../assets/images/cadenas.svg'
import GoodFindIcon from '../../assets/images/goodFindIcon.svg'
import OutilSVG from '../../assets/images/OUTIL.svg'
import { Howl } from 'howler'
import SerrureSound from '../../assets/sound/JEU_SERRURE_ZONE_ROUGE.mp3'
import { clearPrewarmedResources } from 'mapbox-gl'
import useSound from 'use-sound';
import Help from '../Help'

const initialState = {
    bars: [{
        isValid: false,
        elevation: 0,
        speed: 1.0,
        startZone: 50,
        heightZone: 20
    }, {
        isValid: false,
        elevation: 0,
        speed: 0.7,
        startZone: 70,
        heightZone: 25

    }, {
        isValid: false,
        elevation: 0,
        speed: 1.0,
        startZone: 45,
        heightZone: 15
    }, {
        isValid: false,
        elevation: 0,
        speed: 1.2,
        startZone: 60,
        heightZone: 25

    }, {
        isValid: false,
        elevation: 0,
        speed: 1.2,
        startZone: 25,
        heightZone: 20
    }],
    currentBar: 0
}

const Pression = ({ playPlanqueOuverte, launchNotifAfterEnigme, playJeuReussi, launchNotification, planques, setIsOnEnigme, currentPlanque, map, setPlanques, setCurrentStep }) => {
    const outilRef = useRef(null)
    const [isTriggered, setIsTriggered] = useState(false)
    const [state, setState] = useState(initialState)
    const [enigmeState, setEnigmeState] = useState('enigme')

    const [playCrochet] = useSound(SerrureSound);
    
    const [isMobile] = useState(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
    const [phrases] = useState(['Crochète la serrure', '', ' ', 'Crochetage réussi '])
   

    useInterval(() => {
        if (isTriggered) {
            const _state = state
            const _bar = _state.bars[_state.currentBar]
            _bar.elevation = Math.min(_bar.elevation + (isMobile ? _state.bars[_state.currentBar].speed * 7 : _state.bars[_state.currentBar].speed * 2.5), 100)
            _state.bars[state.currentBar] = _bar

            setState({
               ...state,
               bars: _state.bars
            })
        }
      }, 30);

      useEffect(() => {
        outilRef.current.style.left = `calc(${10 + state.currentBar * 20}% - 400px)`
      }, [state.currentBar])

    useEffect(() => {
        if (isTriggered === false) {
            if (state.bars[state.currentBar].elevation > state.bars[state.currentBar].startZone && state.bars[state.currentBar].elevation < state.bars[state.currentBar].startZone + state.bars[state.currentBar].heightZone) {
                
                if (state.currentBar + 1 < 5) {
                    setState({ ...state, currentBar: state.currentBar + 1 })
                    playCrochet()
                } else {
                    setEnigmeState('goodFind')
                    playJeuReussi()

                    setTimeout(() => {
                        playPlanqueOuverte()
                    }, 200)

                    setTimeout(() => {
                        launchNotifAfterEnigme()
                    }, 400)

                    setState({ ...state, currentBar: state.currentBar + 0 })
                    setTimeout(() => {
                        setIsOnEnigme(false)
                        handleUpdate(planques.findIndex((planque) => planque.id === currentPlanque))
                    }, 3000)
                }
            } else {
                const _bars = state.bars
                _bars[state.currentBar].elevation = 0
                setState({ ...state, bars: _bars })
            }
        }
    }, [isTriggered])


    useEffect(() => {
        launchNotification('LIBANAIS', 'Vas-y fais péter la serrure mon gars !')

        setTimeout(() => {
            launchNotification('LIBANAIS', "T’es chelou tu mets trop d'temps", 3000)
        }, 8000)
    },  [])
    
    const handleUpdate = (index) => {
        const _planque = planques[index]
        _planque.active = false
        map.current.setPaintProperty(_planque.id, 'fill-color', "rgba(255, 255, 255, 0.3)");
        const newPlanques = [...planques];
        newPlanques[index] = _planque;
        setPlanques(newPlanques);
      }   

    return (
        <section className="pressionContainer">
            <Help text={"Appuie sur le bouton PRESS et relâche le pour que le curseur blanc tombe dans la zone rouge"} />
        <DoorIndication enigmeState={enigmeState} type="pression" img={CadenasSVG} isLocked={true} text={phrases} enigmeText={"Reconstitue l’iris pour dévérouiller la porte"} />
            <section className="pression">
                {
                    state.bars.map((bar, index) => {
                        return (
                            <div key={index} className="barContainer">
                                <div style={{
                                    bottom: bar.startZone + "%",
                                    height: bar.heightZone + "%"
                                }} className="limit"></div>
                                <div style={{
                                    height: bar.elevation + "%"
                                }} className="bar"></div>
                            </div>
                        )
                    })
                }
                
                <img className='outil' ref={outilRef} src={OutilSVG} alt="" />
            </section>

               
            {
            enigmeState === "enigme" && (
                <button
                    onTouchStart={() => {setIsTriggered(true)}}
                    onMouseDown={() => setIsTriggered(true)}
                    onMouseUp={() => setIsTriggered(false)}
                    onTouchEnd={() => setIsTriggered(false)}
                    className={`pressionBtn ${isTriggered ? 'on' : ''}`}
                >press</button>
            )
            }
            {
            enigmeState === "goodFind" && (
                <img className="smallIndicationIcon" src={GoodFindIcon} alt="" />
            )
            }
        </section>
    )
}

export default Pression