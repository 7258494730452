import React, { useState, useEffect } from 'react'
import SilhouetteSVG from '../../assets/images/silhouetteSVG.png'
import CrossSVG from '../../assets/images/cross.svg'
import FindIndexClick from '../../assets/sound/GENERAL_CLIQUE_GENERAL_BOUTONS_TEMOINS_PLANQUES.mp3'

const Indication = ({ resetState, speakText }) => {
    return (
        <div className="indicationContainer">
            <div className="mainIndicationContainer">
                <div className="indication" >
                    <div className="silhouetteContainer">
                        <img src={SilhouetteSVG} alt="" />
                        <p>{speakText.name}</p>
                        <button onClick={resetState}><img src={CrossSVG} alt="" /></button>
                    </div>
                    <p className="text">{speakText.text}</p>
                </div>
            </div>
        </div>
    )
}

export default Indication