import React, { useState, useEffect, useRef } from 'react'
import ReactHowler from 'react-howler/lib/ReactHowler'
import IMessageIcon from '../../assets/icons/imessage.png'
import AudioNotification from '../../assets/sound/src_assets_songs_iphone-notif.mp3'
import './index.scss'
import useSound from 'use-sound';

const Notification = ({ boucleProd, text, author, notificationRef }) => {
    const [play] = useSound(AudioNotification);

    useEffect(() => {
        if (text) {
            play()
        }
    }, [text])

    return (
        <div ref={notificationRef} className={`notification`}>
            <header>
                <div className="topNotification_left">
                    <img src={IMessageIcon} className="imessageIcon" alt="" />
                    <p>Messages</p>
                </div>
                <p>maintenant</p>
            </header>

            <main>
                <h1>{author}</h1>
                <p>{ text }</p>
            </main>
        </div>
    )
}

export default Notification