import React, { useEffect, useState, useRef } from 'react'
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import FingerPrint1 from '../../assets/images/fingerprint_1.png'
import FingerPrint2 from '../../assets/images/fingerprint_2.png'
import FingerPrint3 from '../../assets/images/fingerprint_3.png'
import FingerPrint4 from '../../assets/images/fingerprint_4.png'
import FingerPrint5 from '../../assets/images/fingerprint_5.png'
import FakeFingerPrint2 from '../../assets/images/fake_fingerprint_2.png'
import FakeFingerPrint3 from '../../assets/images/fake_fingerprint_3.png'
import indications from '../CheckPlanques/indications';
import './index.scss'
import DoorIndication from '../../components/DoorIndication';
import EmpreinteImg from '../../assets/images/FULL_EMPREINTE.png'
import GoodFindIcon from '../../assets/images/goodFindIcon.svg'
import BadFindIcon from '../../assets/images/badFindIcon.svg'
import ClicFingerprint from '../../assets/sound/JEU_EMPREINTE_CLIQUE.mp3'
import ScanSound from '../../assets/sound/JEU_EMPREINTE_SCAN.mp3'
import Help from '../../components/Help';
import useSound from 'use-sound';

const Enigme = ({ launchNotification, launchNotifAfterEnigme, playPlanqueOuverte, playJeuReussi, playJeuRate, isOnEnigme, map, setCurrentStep, setIsOnEnigme, currentPlanque, setCurrentPlanque, planques, setPlanques }) => {
    const fingerprint_ref = useRef(null)
    const scannerRef = useRef(null)
    const [state, setState] = useState("enigme")
    // scanning
    // badScan
    // goodScan
    const [playClicFingerprint, { stop }] = useSound(ClicFingerprint);
    const [playScan] = useSound(ScanSound);

      const [currentState, setCurrentState] = useState({
        first: indications.find((planque) => planque.id === currentPlanque).enigme_order[0],
        second: indications.find((planque) => planque.id === currentPlanque).enigme_order[1],
        third: indications.find((planque) => planque.id === currentPlanque).enigme_order[2],
        fourth: indications.find((planque) => planque.id === currentPlanque).enigme_order[3],
        fifth: indications.find((planque) => planque.id === currentPlanque).enigme_order[4]
      })

      const [desiredState] = useState({
        first: 0,
        second: 1,
        third: 2,
        fourth: 3,
        fifth: 5
      })

      const [phrases] = useState([
        " Scan l’empreinte", "Scan en cours", "Accès refusé", "Accès autorisé"
      ])

      const handleUpdate = (index) => {
        const _planque = planques[index]
        _planque.active = false
        map.current.setPaintProperty(_planque.id, 'fill-color', "rgba(255, 255, 255, 0.3)");
        const newPlanques = [...planques];
        newPlanques[index] = _planque;
        setPlanques(newPlanques);
      }      

      const updateStateAndCheck = (e, num) => {
        playClicFingerprint()
        setCurrentState({
          ...currentState,
          [num]: e
        })
      }

      const validateEnigme = () => {
        scannerRef.current.classList.add('on')
        setState('scanning')
        playScan()

        setTimeout(() => {
          scannerRef.current.classList.remove('on')

          if (currentState.first === desiredState.first &&
            currentState.second === desiredState.second &&
            currentState.third === desiredState.third &&
            currentState.fourth === desiredState.fourth &&
            currentState.fifth === desiredState.fifth) {
              setState('goodFind')

              playJeuReussi()

              setTimeout(() => {
                  playPlanqueOuverte()
              }, 200)

              setTimeout(() => {
                  launchNotifAfterEnigme()
              }, 400)

              setTimeout(() => {
                setIsOnEnigme(false)
                handleUpdate(planques.findIndex((planque) => planque.id === currentPlanque))
              }, 3000)
            } else {
              setState('badFind')
              playJeuRate()
  
              setTimeout(() => {
                setState('enigme')
              }, 3000)
            }
        }, 3500)

        
      }

      useEffect(() => {
        launchNotification('LIBANAIS', 'Trouve la bonne empreinte pour rentrer.')

        setTimeout(() => {
          launchNotification('LIBANAIS', 'Hey fais vite là ', 3000)
        }, 8000)
      }, [])

      return (
        <section className="enigmePage">
          <Help text={"Clique sur chaque partie d’empreinte pour la reconstituer"} />
           <DoorIndication enigmeState={state} type="fingerprint" img={EmpreinteImg} isLocked={true} text={phrases} enigmeText={"Reconstitue l’iris pour dévérouiller la porte"} />
            <div className="fingerprintContainer">
            <div className='fingerprint' ref={fingerprint_ref}>
            <SliderDiv disable={true} currentState={currentState} name={"first"} updateStateAndCheck={updateStateAndCheck} />
            <SliderDiv currentState={currentState} name={"second"} updateStateAndCheck={updateStateAndCheck} />
            <SliderDiv currentState={currentState} name={"third"} updateStateAndCheck={updateStateAndCheck} />
            <SliderDiv currentState={currentState} name={"fourth"} updateStateAndCheck={updateStateAndCheck} />
            <SliderDiv currentState={currentState} name={"fifth"} updateStateAndCheck={updateStateAndCheck} />
      </div>
      <div ref={scannerRef} className="scanner"></div>
      </div>
      
{
  state === "enigme" && (
    <button className="validate" onClick={validateEnigme}>Valider</button>
  )
}
{
  state === "scanning" && (
    <p className="loadingText">...</p>
  )
}
{
  state === "goodFind" && (
    <img className="smallIndicationIcon" src={GoodFindIcon} alt="" />
  )
}
{
  state === "badFind" && (
    <img className="smallIndicationIcon" src={BadFindIcon} alt="" />
  )
}

        </section>
      );
}

const SliderDiv = ({ name, updateStateAndCheck, currentState, disable }) => {
  const sliderRef = useRef(null)

  var settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: disable ? false : true
  };
  
  const next = () => {
    if (disable) {
      return
    }
    sliderRef.current.slickNext();

  }
  
  return (
    <Slider ref={sliderRef} {...{
      ...settings,
      initialSlide: currentState[name]
    }} afterChange={(e) => updateStateAndCheck(e, name)}>
      <div onClick={next}>
        <img src={FingerPrint1} alt="" />
      </div>
      <div onClick={next}>
        <img src={FingerPrint2} alt="" />
      </div>
      <div onClick={next}>
        <img src={FingerPrint3} alt="" />
      </div>
      <div onClick={next}>
        <img src={FingerPrint4} alt="" />
      </div>
      <div onClick={next}>
        <img src={FakeFingerPrint3} alt="" />
      </div>
      <div onClick={next}>
        <img src={FingerPrint5} alt="" />
      </div>
      <div onClick={next}>
        <img src={FakeFingerPrint2} alt="" />
      </div>
    </Slider>
  )
}

export default Enigme