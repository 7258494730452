import React, { useState, useEffect, useRef } from 'react'
import './index.scss'
import TopInfosCall from './topInfos'
import CallIcon from '../../assets/icons/call.svg'
import EndCallIcon from '../../assets/icons/endCall.svg'
import FacetimeIcon from '../../assets/icons/facetime.svg'
import ContactIcon from '../../assets/icons/contact.svg'
import PlusIcon from '../../assets/icons/plus.svg'
import ClavierIcon from '../../assets/icons/clavier.svg'
import MuteIcon from '../../assets/icons/mute.svg'
import SpeakerIcon from '../../assets/icons/speaker.svg'
import MessageIcon from '../../assets/icons/message.svg'
import RemindIcon from '../../assets/icons/remind.svg'
import IphoneAudio from '../../assets/sound/apple-iphone-basic-ringtone.mp3'
import {Howl} from 'howler';
import DAUziAudio1 from "../../assets/sound/PREMIER_APPEL.mp3"
import DAUziAUdio2 from "../../assets/sound/DEUXIEME_APPEL.mp3"
import DAUziAUdio3 from '../../assets/sound/TROISIEME_APPEL.mp3'
import ReactHowler from 'react-howler/lib/ReactHowler'

const Call = ({ boucleProd, currentStep, setCurrentStep, videoFacetimeRef, setIsOpenFacetime }) => {
    const [hasResponded, setHasResponded] = useState(false)


    useEffect(() => {
        window.digitalData={							
            settings:{						
                reportSuites:"wmg,wmgfr"					
            },						
            page:{						
                pageInfo:{					
                    pageName:"DA Uzi:Le chemin des braves Game and Contest:Landing:Phone",				
                    server:"DA Uzi:Site",				
                    platform:"MIS Custom Page",				
                    devTeam:"Warner Music France"				 
                },					
                category:{					
                    primaryCategory:"DA Uzi:Landing Page",				
                    pageType:"Landing Page:Contest"				
                }					
            },						
            content:{						
                artist:"DA Uzi",					
                label:"Warner Music International",					
                sublabel:"Warner Music France"					
            }						
        }									
                                        
        document.dispatchEvent(new CustomEvent('SPAPageTriggered'));

        return () => {
            if (currentStep === 1) {
                boucleProd.current.AudioCall1.stop()
            } else if (currentStep === 3) {
                boucleProd.current.AudioCall2.stop()
            } else if (currentStep === 5) {
                boucleProd.current.AudioCall3.stop()
            }
        }
    }, [])

    useEffect(() => {
        if (currentStep === 0.5) {

            setTimeout(() => {
                setCurrentStep(1)
            }, 5000)
        }
    }, [currentStep])

    return (
        <section className={`call ${hasResponded === false && 'first'}`}>
            <TopInfosCall boucleProd={boucleProd} setCurrentStep={setCurrentStep} currentStep={currentStep} hasResponded={hasResponded} />
            {/* <button onClick={() => {
                navigator.vibrate([3000, 500, 2000, 500, 1000])
            }}>test</button> */}
            {
                hasResponded === false && (
                    <>

                        <ReactHowler
                            src={IphoneAudio}
                            playing={true}
                            loop={true}
                            html5={true}
                            volume={1}
                        />
                        <div className="respond_btns">
                            <div className="respond_btns-left">
                                <button className='remind_me secondary_btn'>
                                    <img src={RemindIcon} alt="" />
                                    <p>Rappel</p>
                                </button>

                                <img src={EndCallIcon} className='main_btn' alt="" />
                                <p>Refuser</p>
                            </div>

                            <div className="respond_btns-right">
                                <button className="message secondary_btn">
                                    <img src={MessageIcon} alt="" />
                                    <p>Message</p>
                                </button>

                                <img src={CallIcon} alt="" className=' main_btn' onClick={() => {

                                    if (currentStep === 6) {
                                        setCurrentStep(7)
                                        setIsOpenFacetime(true)
                                    } else {
                                        setHasResponded(true)
                                    }
                                }} />
                                <p>Accepter</p>
                            </div>
                        </div>
                    </>
                )
            }

            {
                hasResponded === true && (
                    <>
                    {
                        currentStep === 0 && (
                            <ReactHowler
                                src={DAUziAudio1}
                                playing={true}
                                html5={true}
                                volume={1}
                                onEnd={() => setCurrentStep(1)}
                            />
                        )
                    }
                    
                    {
                        currentStep === 2 && (
                            <ReactHowler
                                src={DAUziAUdio2}
                                playing={true}
                                html5={true}
                                volume={1}
                                onEnd={() => setCurrentStep(3)}
                            />
                        )
                    }
                    
                    {
                        currentStep === 4 && (
                            <ReactHowler
                                src={DAUziAUdio3}
                                playing={true}
                                html5={true}
                                volume={1}
                                onEnd={() => setCurrentStep(5)}
                            />
                        )
                    }
                        <section className='onCall_secondary'>
                            <div>
                                <img src={MuteIcon} alt="" />
                                <p>silence</p>
                            </div>


                            <div>
                                <img src={ClavierIcon} alt="" />
                                <p>clavier</p>
                            </div>


                            <div>
                                <img src={SpeakerIcon} alt="" />
                                <p>haut-parleur</p>
                            </div>


                            <div>
                                <img src={PlusIcon} alt="" />
                                <p>nouvel appel</p>
                            </div>


                            <div>
                                <img src={FacetimeIcon} alt="" />
                                <p>FaceTime</p>
                            </div>


                            <div>
                                <img src={ContactIcon} alt="" />
                                <p>contacts</p>
                            </div>
                        </section>


                        <img src={EndCallIcon} className='main_btn' alt="" />
                    </>
                )
            }
        </section>
    )
}

export default Call