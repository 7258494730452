import React, { useState } from 'react'
import Cross from '../../assets/images/cross.svg'
import './index.scss'

const Help = ({ text }) => {
    const  [isOpen, setIsOpen] = useState(false)

    return (
        <>
            {isOpen === false && (<button onClick={() => setIsOpen(true)} className='helpBtn'>?</button>)}
            {isOpen && (<section className='textHelp'><p>{ text }</p> <img src={Cross} onClick={() => setIsOpen(false)} alt="" /></section>)}
        </>
    )
}

export default Help