import React, { useEffect } from 'react'
import './index.scss'
import IntroductionImage from '../../assets/images/visuelIntro.jpg'
import Footer from '../../components/Footer'

const Introduction = ({ introductionRef }) => {
    useEffect(() => {
        window.digitalData={							
            settings:{						
              reportSuites:"wmg,wmgfr"					
            },						
            page:{						
              pageInfo:{					
                pageName:"DA Uzi:Le chemin des braves Game and Contest:Landing:Home",				
                server:"DA Uzi:Site",				
                platform:"MIS Custom Page",				
                devTeam:"Warner Music France"				 
              },					
              category:{					
                primaryCategory:"DA Uzi:Landing Page",				
                pageType:"Landing Page:Contest"				
              }					
            },						
            content:{						
              artist:"DA Uzi",					
              label:"Warner Music International",					
              sublabel:"Warner Music France"					
            }						
          }

        document.dispatchEvent(new CustomEvent('SPAPageTriggered'));		
    }, [])

    return (
        <>
          <section ref={introductionRef} className={`introduction`}><img src={IntroductionImage} alt="" />
          <Footer /></section>
      </>
        )
}

export default Introduction