const indications = [
    {
        id: "Stade de France",
        zone: [2.3624231, 48.91889],
        text: "IL A TAILLÉ IL EST VERS LA COURNEUVE BK IL LA VU AU FEU ",
        isPlanque: false,
        active: true,
        enigme_order: [0, 3, 5, 4, 2, 2],
        type: "electricite"
    },

    {
        id: "Sevran",
        zone: [2.3896123, 48.9267236],
        text: "CE GADAYE IL FUIT A AUBERVILLIERS",
        isPlanque: false,
        active: true,
        enigme_order: [0, 3, 5, 4, 2, 2],
        type: "fingerprint"
    },

    {
        id: "Blanc Menil",
        zone: [2.3624231, 48.94723],
        text: "AH WE NAN IL EST A SAINT OUEN EN FAIT",
        isPlanque: false,
        active: true,
        enigme_order: [0, 3, 5, 4, 2, 2],
        type: "crochetage"
    }
]

export default indications