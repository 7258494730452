import React, { useEffect, useState } from 'react'
import "./index.scss"

const NameScreen = ({ boucleProd, setUserName, setCurrentStep }) => {
    const [inputValue, setInputValue] = useState('')

    return (
        <section className='nameScreen'>
           <div className='center'>
           <p>Entre ton <span>blaze</span></p>
            <input onChange={(e) => setInputValue(e.target.value)} type="text" placeholder='prénom' />
           </div>

            <button disabled={inputValue.length > 0 ? false : true} onClick={() => {
                if (inputValue.length > 0) {
                    setUserName(inputValue)
                    setCurrentStep(-0.5)
                }
                
                boucleProd.current.IndicationAudio.play()
            }}>Valider</button>
        </section>
    )
}

export default NameScreen